// Add your custom JS here.
/**
 * Mobile Menu
 */
jQuery(function ($) {
    var navIcon     = $('.menu-toggle');
    var navMain     = $('#main-nav');
    var siteHeader  = $('#site-header');
    var headerHeight = $(navMain).height();
    // function checkHeaderScroll() {
    //     var scrollPosition = $(window).scrollTop();
    //     if (scrollPosition > 1) {
    //         navIcon.addClass('visible');
    //         navMain.addClass('scrolled');
    //         navMain.removeClass('top');
    //         siteHeader.addClass('scrolled');
    //         siteHeader.removeClass('top');
    //     } else {
    //         navIcon.removeClass('visible');
    //         navMain.removeClass('scrolled');
    //         navMain.addClass('top');
    //         siteHeader.removeClass('scrolled');
    //         siteHeader.addClass('top');
    //     }
    // }
    // $(window).load(function() {
    //     checkHeaderScroll();
    // });
    // $(window).on('scroll', function(){
    //     checkHeaderScroll();
    // });
    // Toggle menu overlay
    $('.menu-toggle').on('click', function(e) {
        $('#menu-icon').toggleClass('active');
        $('#main-navigation').toggleClass('overlay');
        //$('.site-menu-container').toggleClass('overlay');
        $('body').toggleClass('menu-active');
        e.preventDefault();
    });
});

// When the user scrolls the page, execute myFunction
window.onscroll = function() {
  myFunction()
};

// Get the header
var siteHeader  = document.getElementById('site-header');
var metaHeader  = document.getElementById('site-header-meta');
var pageHeader  = document.getElementById('page-header');

// Get the offset position of the navbar
var sticky = siteHeader.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove 'sticky' when you leave the scroll position
function myFunction() {
  if (window.scrollY > sticky) {
    siteHeader.classList.add('sticky');
    metaHeader.classList.add('scrolled');
    pageHeader.classList.remove('pt-navbar');
    pageHeader.classList.add('pt-navbar-scrolled');
  } else {
    siteHeader.classList.remove('sticky');
    metaHeader.classList.remove('scrolled');
    pageHeader.classList.add('pt-navbar');
    pageHeader.classList.remove('pt-navbar-scrolled');
  }
}